const awsConfig = {
    aws_project_region: "ap-southeast-2",
    aws_appsync_region: "ap-southeast-2",
    aws_cognito_region: "ap-southeast-2",
    aws_user_pools_id: "ap-southeast-2_xF5Mu7t2e",
    aws_user_pools_web_client_id: "3f7m1ve7g39a7a07g01c4g9ul1",
    oauth: {
      domain: "practitioner-dev.auth.ap-southeast-2.amazoncognito.com",
      scope: [
          "openid",
          "profile"
      ],
      redirectSignIn: "https://practitioner-dev.mydna.life/welcome",
      redirectSignOut: "https://practitioner-dev.mydna.life/end",
      responseType: "code"
    },
    federationTarget: "COGNITO_USER_POOLS"
  };
  export default awsConfig;